<template>
<section class="container h-100">
    <div class="row h-100 align-items-center">
        <div class="col-12 col-lg-6 text-white">
            <h1 class="text-left font-weight-light mt-5 mt-lg-0">
                Acesse a nossa plataforma e construa um <b class="font-weight-bold">atendimento personalizado!</b>
            </h1>
        </div>
        <div class="col-12 col-lg-6">
            <div class="card my-5">
                <div class="card-body p-5 p-md-5">
                    <h4 class="title-border mb-4 text-left">Redefinir senha</h4>

                    <validation-observer ref="resetPasswordValidationForm" tag="form">
                        <div class="row py-2">
                            <div class="col-12 my-2">
                                <validation-provider
                                    #default="{ errors }"
                                    name="email"
                                    rules="required|email"
                                >
                                    <input 
                                        type="email" 
                                        placeholder="E-mail"
                                        class="form-control"
                                        :class="{ 'is-invalid': errors.length > 0 }"
                                        v-model="form.email"
                                        :readonly="loading"
                                    />
                                </validation-provider>
                            </div>
                            <div class="col-12 my-2">
                                <validation-provider
                                    #default="{ errors }"
                                    name="senha"
                                    rules="required|min:8"
                                >
                                    <div class="input-group">
                                        <input
                                            :type="showPassword ? 'text' : 'password'" 
                                            placeholder="Senha"
                                            class="form-control"
                                            :class="{ 'is-invalid': errors.length > 0 }"
                                            v-model="form.password"
                                            :readonly="loading"
                                        >
                                        <span 
                                            class="input-group-text"
                                            role="button"
                                            @click="showPassword = !showPassword"
                                        >
                                            <font-awesome-icon 
                                                style="width: 15.5px"
                                                :icon="`fas ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`"
                                            />
                                        </span>
                                    </div>
                                </validation-provider>
                            </div>
                            <div class="col-12 my-2">
                                <validation-provider
                                    #default="{ errors }"
                                    name="confirmar senha"
                                    rules="required|confirmed:senha"
                                >
                                    <div class="input-group">
                                        <input
                                            :type="showPassword ? 'text' : 'password'" 
                                            placeholder="Confirmar Senha"
                                            class="form-control"
                                            :class="{ 'is-invalid': errors.length > 0 }"
                                            v-model="form.password_confirmation"
                                            :readonly="loading"
                                        >
                                        <span 
                                            class="input-group-text"
                                            role="button"
                                            @click="showPassword = !showPassword"
                                        >
                                            <font-awesome-icon 
                                                style="width: 15.5px"
                                                :icon="`fas ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`"
                                            />
                                        </span>
                                    </div>
                                </validation-provider>
                            </div>
                        </div>
                    </validation-observer>

                    <div class="row align-items-center secondary-colors">
                        <div class="col-12 col-lg-auto">
                            <button :disabled="loading" @click="submit" class="btn btn-primary px-5 mr-lg-4 mt-3 w-100 w-lg-auto">
                                <span v-if="loading" class="spinner-border spinner-border-sm" role="status"></span>
                                <span v-else>Redefinir</span>
                            </button>
                        </div>
                        <div class="col-12 col-lg-auto pt-3">
                            <router-link custom :to="{ name: 'auth-login' }" v-slot="{ href, navigate }">
                                <a :href="href" @click="navigate" class="link-primary font-weight-bold">
                                    Voltar
                                </a>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import api from "@/api";

export default {
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            required,
            email,
            showPassword: false,
            loading: false,
            form: {
                email: null,
                password: null,
                password_confirmation: null,
                token: null,
            }
        }
    },
    created() {
        const token = this.$route.query.token;

        if(token == null || token == '') {
            this.$router.replace({ name: 'auth-login' });
        }
        this.form.token = token;
    },
    methods: {
        toast(message, type = 'danger') {
            this.$root.$bvToast.toast(message, {
                title: 'Redefinir Senha',
                variant: type
            });
        },
        async submit() {
            const validated = await this.$refs.resetPasswordValidationForm.validate();

            if(!validated) return;

            this.loading = true;

            api.post('/users/reset-password', this.form)
            .then(response => {
                const { type, message, errors } = response.data;

                if(type == 'success') {
                    this.toast(message, 'success');
                    return this.$router.push({ name: 'auth-login' });
                }
                this.toast(errors.length > 0 ? errors[0] : message);
            })
            .catch(error => {
                var message = 'Não foi possível processar sua requisição corretamente. Tente novamente mais tarde.';

                if (error.response) {
                    const { errors } = error.response.data;
                    message = errors.length > 0 ? errors[0] : error.response.data.message;
                }
                return this.toast(message);
            })
            .finally(() => this.loading = false);
        }
    }
}
</script>